import React, { useEffect, useMemo, useState, type FC } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { UploadFile } from 'antd';
import { FormApi } from 'final-form';
import { omit, pick } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import { HREmployeeCustomTimeOffSetting } from 'modules/Common/types';
import { IEmployeeShortValues } from 'modules/Common/types/employee';
import {
	BankHolidayBaseType,
	BankHolidayType,
	OnOffBoardingSettingType,
	OnOffBoardingSettingTypes,
} from 'modules/Common/types/hrModuleTypes';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { hrDucks } from 'modules/HR/ducks';
import { GenericType } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';

import { prepareEmployeeAttachments } from '../CreateEditEmployee/CreateEditEmployee.helpers';

import { AddEditCustomHolidayModal } from './AddEditCustomHolidayModal/AddEditCustomHolidayModal';
import { AddEditCustomTimeOffModal } from './AddEditCustomTimeOffModal/AddEditCustomTimeOffModal';
import { BankHolidaysModal } from './BankHolidaysModal/BankHolidaysModal';
import { EnableDisableWorkingDaysModal } from './EnableDisabeWorkingDaysModal/EnableDisableWorkingDaysModal';
import HRBoardingBlock from './HRBoardingBlock/HRBoardingBlock';
import HRHolidayAbsencePolicyBlock from './HRHolidayAbsencePolicyBlock/HRHolidayAbsencePolicyBlock';
import {
	AllowedWorkWeekFormFields,
	defaultWorkWeekSettingsValues,
} from './HRModuleSettings.constants';
import {
	filterCustomBankHolidays,
	filterPublicBankHolidaysForYear,
	getImportHolidaysDraggerProps,
} from './HRModuleSettings.helpers';
import { Styled } from './HRModuleSettings.styled';
import {
	EmployeeOptions,
	WorkWeekSettingsValuesFromBackendType,
	WorkWeekSettingsValuesType,
} from './HRModuleSettings.types';
import { ImportHolidaysModal } from './ImportHolidaysModal/ImportHolidaysModal';

type HRModuleSettingsProps = {
	employeeList: IEmployeeShortValues[];
	employeeOptions: { value: number; label: string }[];
	vacationSettingsList: HREmployeeCustomTimeOffSetting[];
	workWeekSettings: WorkWeekSettingsValuesFromBackendType;
	loading: GenericType;
	getOnOffBoardingSettingsRequested: () => void;
	setOnOffBoardingSettingsRequested: (data: OnOffBoardingSettingType) => void;
	onOffBoardingSettinsg: OnOffBoardingSettingType[];
	allBankHolidays: BankHolidayType[];
	getEmployeeList: () => void;
	getEmployeeOptions: () => void;
	getHRVacationSettingsRequested: () => void;
	createUpdateVacationSettingsRequested: (
		values: { employee: number | null; days: number },
		callback?: (error?: string) => void,
	) => void;
	deleteVacationSettingsRequested: (id: number, callback?: () => void) => void;
	getWorkWeekSettingsRequested: () => void;
	updateWorkWeekSettingsRequested: (data: WorkWeekSettingsValuesType) => void;
	getBankHolidaysRequested: (params: { onlyCustom: boolean; expandRecurring: boolean }) => void;
	createCustomHolidayRequested: (data: BankHolidayBaseType, callback?: () => void) => void;
	updateCustomHolidayRequested: (
		data: Omit<BankHolidayType, 'client'>,
		callback?: () => void,
	) => void;
	deleteCustomHolidayRequested: (id: number, callback?: () => void) => void;
};

interface IPostSettingsValues {
	onboardingDescription: string;
	offBoardingDescription: string;
}

const HRModuleSettings: FC<HRModuleSettingsProps> = ({
	getOnOffBoardingSettingsRequested,
	setOnOffBoardingSettingsRequested,
	onOffBoardingSettinsg,
	employeeOptions,
	vacationSettingsList,
	workWeekSettings,
	allBankHolidays,
	loading,
	getEmployeeOptions,
	getHRVacationSettingsRequested,
	createUpdateVacationSettingsRequested,
	deleteVacationSettingsRequested,
	getWorkWeekSettingsRequested,
	updateWorkWeekSettingsRequested,
	getBankHolidaysRequested,
	createCustomHolidayRequested,
	updateCustomHolidayRequested,
	deleteCustomHolidayRequested,
}) => {
	const [bankHolidays, setBankHolidays] = useState<BankHolidayType[]>([]);
	const [customBankHolidays, setCustomBankHolidays] = useState<BankHolidayType[]>([]);
	const [isImportHolidaysModalVisible, setIsImportHolidaysModalVisible] = useState<boolean>(false);
	const [workWeekValues, setWorkWeekValues] = useState<WorkWeekSettingsValuesType>(
		defaultWorkWeekSettingsValues,
	);
	const [workingWeekModalProps, setWorkingWeekModalProps] = useState<{
		message: string | null;
		form: FormApi<WorkWeekSettingsValuesType, Partial<WorkWeekSettingsValuesType>> | null;
		field: string;
		value: boolean;
	}>({ message: null, form: null, field: '', value: false });
	const [vacationLeaveDays, setVacationLeaveDays] = useState<HREmployeeCustomTimeOffSetting[]>([]);
	const [isAddEditCustomHolidayModalVisible, setIsAddEditCustomHolidayModalVisible] = useState<
		boolean | number
	>(false);
	const [isBankHolidaysModalVisible, setIsBankHolidaysModalVisible] = useState<boolean>(false);
	const [employeeListOptions, setEmployeeListOptions] = useState<EmployeeOptions[]>([]);
	const [isAddEditVacationsModalVisible, setIsAddEditVacationsModalVisible] = useState<
		boolean | number
	>(false);
	const [onboardingFileList, setOnboardingFileList] = useState<UploadFile[]>([]);
	const [offBoardingFileList, setOffBoardingFileList] = useState<UploadFile[]>([]);
	const [newOnboardingFiles, setNewOnboardingFiles] = useState<Set<string>>(new Set());
	const [newOffboardingFiles, setNewOffboardingFiles] = useState<Set<string>>(new Set());

	useEffect(() => {
		const onboardingSetting = onOffBoardingSettinsg.find(
			(setting) => setting.type === OnOffBoardingSettingTypes.ONBOARDING,
		);
		const offBoardingSetting = onOffBoardingSettinsg.find(
			(setting) => setting.type === OnOffBoardingSettingTypes.OFFBOARDING,
		);

		const initialOnboardingFileList = onboardingSetting?.attachments
			? prepareEmployeeAttachments(onboardingSetting?.attachments)
			: [];
		const initialOffboardingFileList = offBoardingSetting?.attachments
			? prepareEmployeeAttachments(offBoardingSetting?.attachments)
			: [];
		setOnboardingFileList(initialOnboardingFileList);
		setOffBoardingFileList(initialOffboardingFileList);
	}, [onOffBoardingSettinsg]);

	useEffect(() => {
		if (allBankHolidays.length) {
			setBankHolidays(filterPublicBankHolidaysForYear(allBankHolidays));
			setCustomBankHolidays(filterCustomBankHolidays(allBankHolidays));
		}
	}, [allBankHolidays]);

	const handleUpdateOnboardingAttachmentsList = (list: UploadFile[]) => {
		setOnboardingFileList(list);
	};

	const handleUpdateOnboardingForNewFiles = (list: Set<string>) => {
		setNewOnboardingFiles(list);
	};

	const handleUpdateOffboardingAttachmentsList = (list: UploadFile[]) => {
		setOffBoardingFileList(list);
	};

	const handleUpdateOffboardingForNewFiles = (list: Set<string>) => {
		setNewOffboardingFiles(list);
	};

	const initialValues = useMemo(
		() => ({
			...pick(workWeekSettings, AllowedWorkWeekFormFields),
		}),
		[workWeekSettings],
	);

	useMount(() => {
		getHRVacationSettingsRequested();
		getWorkWeekSettingsRequested();
		getEmployeeOptions();
		getOnOffBoardingSettingsRequested();
		getBankHolidaysRequested({ onlyCustom: false, expandRecurring: false });
	});

	useEffect(() => {
		employeeOptions && setEmployeeListOptions(employeeOptions);
	}, [employeeOptions]);

	useEffect(() => {
		vacationSettingsList && setVacationLeaveDays(vacationSettingsList);
	}, [vacationSettingsList]);

	useEffect(() => {
		workWeekSettings &&
			setWorkWeekValues(
				pick(workWeekSettings, AllowedWorkWeekFormFields) as WorkWeekSettingsValuesType,
			);
	}, [workWeekSettings]);

	const onSubmit = (values: IPostSettingsValues) => {
		const trimedValues = trimSpacesFormValues(values);
		// console.log('Form Values', values);
	};

	const handleOpenAddEditCustomModal = (id?: number) => {
		setIsAddEditCustomHolidayModalVisible(id || true);
	};

	const handleCloseAddEditCustomModal = () => {
		setIsAddEditCustomHolidayModalVisible(false);
	};

	const toggleBankHolidaysModal = () => {
		setIsBankHolidaysModalVisible((prev) => !prev);
	};

	const handleOpenAddEditVacationsModal = (id?: number) => {
		setIsAddEditVacationsModalVisible(id || true);
	};

	const handleCloseAddEditVacationsModal = () => {
		setIsAddEditVacationsModalVisible(false);
	};

	const onBoardingSubmit = (values: OnOffBoardingSettingType) => {
		const attachments = onboardingFileList
			?.filter((file) => file.status === 'done')
			?.map((file) => file?.response?.data);
		setOnOffBoardingSettingsRequested({ ...omit(values, 'id'), attachments });
	};

	const offBoardingSubmit = (values: OnOffBoardingSettingType) => {
		const attachments = offBoardingFileList
			?.filter((file) => file.status === 'done')
			?.map((file) => file?.response?.data);
		setOnOffBoardingSettingsRequested({ ...omit(values, 'id'), attachments });
	};

	const handleAddUpdateVacationSettings = (
		values: { employee: number | null; days: number },
		cb: (error?: string) => void,
	) => {
		createUpdateVacationSettingsRequested(values, cb);
	};

	const handleDeleteVacationSettings = (employeeId: number) => {
		deleteVacationSettingsRequested(employeeId);
	};

	const handleAddCustomHoliday = (values: BankHolidayBaseType, cb?: () => void) => {
		createCustomHolidayRequested(values, () => {
			getBankHolidaysRequested({ onlyCustom: false, expandRecurring: false });
			cb && cb();
		});
	};

	const handleUpdateCustomHoliday = (values: Omit<BankHolidayType, 'client'>, cb?: () => void) => {
		updateCustomHolidayRequested(values, () => {
			getBankHolidaysRequested({ onlyCustom: false, expandRecurring: false });
			cb && cb();
		});
	};

	const handleDeleteCustomHoliday = (id: number) => {
		deleteCustomHolidayRequested(id, () => {
			getBankHolidaysRequested({ onlyCustom: false, expandRecurring: false });
		});
	};

	const handleOpenImportHolidaysModal = () => {
		setIsImportHolidaysModalVisible(true);
	};

	const handleCloseImportHolidaysModal = () => {
		setIsImportHolidaysModalVisible(false);
	};

	const draggerImportProps = (overwritePreviousHolidays = false) =>
		getImportHolidaysDraggerProps(overwritePreviousHolidays, () => {
			getBankHolidaysRequested({ onlyCustom: false, expandRecurring: false });
			handleCloseImportHolidaysModal();
		});

	const handleWorkDayToggle = (
		form: FormApi<WorkWeekSettingsValuesType, Partial<WorkWeekSettingsValuesType>>,
		field: string,
		value: boolean,
	) => {
		const message =
			value === false
				? 'Changing working week setting will automatically decline all not yet approved requests. All previously approved requests will not be changed. Are you sure you would like to proceed?'
				: 'Changing working week setting will automatically adjust all not yet approved requests. All previously approved requests will not be changed. Are you sure you would like to proceed?';
		setWorkingWeekModalProps({ message, form, field, value });
	};

	const handleCloseWorkingDaysModal = () => {
		setWorkingWeekModalProps({ message: null, form: null, field: '', value: false });
	};

	const handleSaveWorkingDays = () => {
		if (workingWeekModalProps.form) {
			const formValuesToSave = {
				...workingWeekModalProps.form.getState().values,
				[workingWeekModalProps.field]: !workingWeekModalProps.value,
			};
			updateWorkWeekSettingsRequested(formValuesToSave);
		}

		setWorkingWeekModalProps({ message: null, form: null, field: '', value: false });
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				render={({ handleSubmit, dirty, values, form }) => {
					return (
						<form onSubmit={handleSubmit}>
							<HRHolidayAbsencePolicyBlock
								customBankHolidays={customBankHolidays}
								vacationLeaveDays={vacationLeaveDays}
								workWeekValues={workWeekValues}
								handleViewBankHolidays={toggleBankHolidaysModal}
								handleUpdateCustomHolidays={handleOpenAddEditCustomModal}
								handleUpdateVacationLeaveDays={handleOpenAddEditVacationsModal}
								handleDeleteVacationSettings={handleDeleteVacationSettings}
								handleDeleteCustomHoliday={handleDeleteCustomHoliday}
								handleAddUpdateVacationSettings={handleAddUpdateVacationSettings}
								handleWorkDayToggle={handleWorkDayToggle}
								handleImportHolidays={handleOpenImportHolidaysModal}
								loading={loading}
							/>
						</form>
					);
				}}
			/>
			<Form
				onSubmit={onBoardingSubmit}
				initialValues={onOffBoardingSettinsg.find(
					(setting) => setting.type === OnOffBoardingSettingTypes.ONBOARDING,
				)}
				autoComplete='off'
				render={({ handleSubmit, dirty, values, form }) => {
					return (
						<form onSubmit={handleSubmit}>
							<HRBoardingBlock
								blockTitle='Onboarding'
								fieldName='onboarding'
								attachmentsList={onboardingFileList}
								newAttachmentsList={newOnboardingFiles}
								user={{ roleId: 5 }}
								handleUpdateAttachmentsList={handleUpdateOnboardingAttachmentsList}
								handleUpdateForNewFiles={handleUpdateOnboardingForNewFiles}
							/>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save Onboarding Setting
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					);
				}}
			/>
			<Form
				onSubmit={offBoardingSubmit}
				initialValues={onOffBoardingSettinsg.find(
					(setting) => setting.type === OnOffBoardingSettingTypes.OFFBOARDING,
				)}
				autoComplete='off'
				render={({ handleSubmit, dirty, values, form }) => {
					return (
						<form onSubmit={handleSubmit}>
							<HRBoardingBlock
								blockTitle='Off-Boarding'
								fieldName='offBoarding'
								attachmentsList={offBoardingFileList}
								newAttachmentsList={newOffboardingFiles}
								user={{ roleId: 5 }}
								handleUpdateAttachmentsList={handleUpdateOffboardingAttachmentsList}
								handleUpdateForNewFiles={handleUpdateOffboardingForNewFiles}
							/>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save OffBoarding Setting
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					);
				}}
			/>
			<AddEditCustomHolidayModal
				isOpen={isAddEditCustomHolidayModalVisible}
				holidaysList={customBankHolidays}
				loading={loading}
				onCancel={handleCloseAddEditCustomModal}
				handleUpdateHoliday={handleUpdateCustomHoliday}
				handleAddHoliday={handleAddCustomHoliday}
			/>
			<BankHolidaysModal
				isOpen={isBankHolidaysModalVisible}
				bankHolidaysList={bankHolidays}
				onCancel={toggleBankHolidaysModal}
			/>
			<AddEditCustomTimeOffModal
				isOpen={isAddEditVacationsModalVisible}
				employeeList={employeeListOptions}
				currentVacationList={vacationLeaveDays}
				onCancel={handleCloseAddEditVacationsModal}
				handleAddUpdateVacationSettings={handleAddUpdateVacationSettings}
			/>
			<EnableDisableWorkingDaysModal
				isOpen={workingWeekModalProps.message}
				onCancel={handleCloseWorkingDaysModal}
				onApprove={handleSaveWorkingDays}
			/>
			<ImportHolidaysModal
				isOpen={isImportHolidaysModalVisible}
				draggerProps={draggerImportProps}
				onCancel={handleCloseImportHolidaysModal}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		vacationSettingsList: hrDucks.hrSelectors.getVacationSettings(state),
		employeeOptions: hrDucks.hrSelectors.getEmployeeOptions(state),
		workWeekSettings: hrDucks.hrSelectors.getWorkWeekSettings(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		onOffBoardingSettinsg: hrDucks.hrSelectors.getOnOffBoardingSettings(state),
		allBankHolidays: hrDucks.hrSelectors.getBankHolidays(state),
	}),
	{
		getOnOffBoardingSettingsRequested: hrDucks.hrActions.getOnOffBoardingSettingsRequested,
		setOnOffBoardingSettingsRequested: hrDucks.hrActions.setOnOffBoardingSettingsRequested,
		getEmployeeOptions: hrDucks.hrActions.getEmployeeOptionsRequested,
		getHRVacationSettingsRequested: hrDucks.hrActions.getHRVacationSettingsRequested,
		createUpdateVacationSettingsRequested: hrDucks.hrActions.createUpdateVacationSettingsRequested,
		deleteVacationSettingsRequested: hrDucks.hrActions.deleteVacationSettingsRequested,
		getWorkWeekSettingsRequested: hrDucks.hrActions.getWorkWeekSettingsRequested,
		updateWorkWeekSettingsRequested: hrDucks.hrActions.updateWorkWeekSettingsRequested,
		getBankHolidaysRequested: hrDucks.hrActions.getBankHolidaysRequested,
		createCustomHolidayRequested: hrDucks.hrActions.createCustomHolidayRequested,
		updateCustomHolidayRequested: hrDucks.hrActions.updateCustomHolidayRequested,
		deleteCustomHolidayRequested: hrDucks.hrActions.deleteCustomHolidayRequested,
	},
)(HRModuleSettings);
