import React, { useEffect, useState, type FC } from 'react';
import { Field, useForm } from 'react-final-form';

import { Input, Popconfirm, Modal } from 'antd';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { HREmployeeCustomTimeOffSetting } from 'modules/Common/types';
import { BankHolidayType } from 'modules/Common/types/hrModuleTypes';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { COLORS } from 'theme';
import { GenericType } from 'types';

import { transformWorkWeekToArray } from '../HRModuleSettings.helpers';
import { WorkWeekSettingsValuesType } from '../HRModuleSettings.types';

import { DayToggleItem } from './DayToggleItem';
import { Styled } from './HRHolidayAbsencePolicyBlock.styled';

type HRHolidayAbsencePolicyBlockProps = {
	customBankHolidays: BankHolidayType[];
	vacationLeaveDays: HREmployeeCustomTimeOffSetting[];
	workWeekValues: WorkWeekSettingsValuesType;
	loading: GenericType;
	handleViewBankHolidays: () => void;
	handleUpdateCustomHolidays: (id?: number) => void;
	handleUpdateVacationLeaveDays: (id?: number) => void;
	handleDeleteVacationSettings: (id: number) => void;
	handleAddUpdateVacationSettings: (
		values: { employee: number | null; days: number },
		cb: (error?: string) => void,
	) => void;
	handleDeleteCustomHoliday: (id: number) => void;
	handleWorkDayToggle: (
		form: FormApi<WorkWeekSettingsValuesType, Partial<WorkWeekSettingsValuesType>>,
		field: string,
		value: boolean,
	) => void;
	handleImportHolidays: () => void;
};

const HRHolidayAbsencePolicyBlock: FC<HRHolidayAbsencePolicyBlockProps> = ({
	customBankHolidays,
	vacationLeaveDays,
	workWeekValues,
	loading,
	handleViewBankHolidays,
	handleUpdateCustomHolidays,
	handleUpdateVacationLeaveDays,
	handleDeleteVacationSettings,
	handleAddUpdateVacationSettings,
	handleDeleteCustomHoliday,
	handleWorkDayToggle,
	handleImportHolidays,
}) => {
	const form = useForm();
	const [genericVacationLeaveDays, setGenericVacationLeaveDays] = useState<number>(0);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleGenericVacationUpdate = () => {
		handleAddUpdateVacationSettings(
			{ employee: null, days: genericVacationLeaveDays },
			(error?: string) => error && updateVacationLeaveDays(),
		);
		handleCancel();
	};

	const updateVacationLeaveDays = () => {
		const genericVacationDays = vacationLeaveDays.find((vacation) => vacation.employee === null);
		if (genericVacationDays) {
			setGenericVacationLeaveDays(genericVacationDays.days);
		}
	};

	useEffect(() => {
		updateVacationLeaveDays();
	}, [vacationLeaveDays]);

	const handleChangeWorkWeek = (field: string, value: boolean) => {
		handleWorkDayToggle(form, field as keyof WorkWeekSettingsValuesType, value);
	};

	return (
		<Styled.Block>
			<Styled.BlockTitle>Holidays & absence policy</Styled.BlockTitle>
			<Styled.Section>
				<Styled.SectionTitle>Working days</Styled.SectionTitle>
				<Styled.WorkingDaysTogglesWrap>
					{transformWorkWeekToArray(workWeekValues).map(({ name, label }) => (
						<DayToggleItem
							key={name}
							dayName={name}
							dayLabel={label}
							onChange={handleChangeWorkWeek}
						/>
					))}
				</Styled.WorkingDaysTogglesWrap>
			</Styled.Section>
			<Styled.Section>
				<Styled.SectionTitle>Bank Holidays</Styled.SectionTitle>
				<Styled.SectionTopLine>
					<Field name='country'>
						{({ input }) => (
							<Styled.SelectAntd
								{...input}
								value={'UK'}
								options={[
									{ label: 'United Kingdom', value: 'UK' },
									{ label: 'United States', value: 'USA' },
								]}
								placeholder='Select Country'
								disabled={true}
							/>
						)}
					</Field>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.secondary}
						onClick={handleViewBankHolidays}
					>
						View Bank Holidays
					</HRThemedButton>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={() => handleUpdateCustomHolidays()}
					>
						Add Custom Holiday
					</HRThemedButton>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={handleImportHolidays}
					>
						Import
					</HRThemedButton>
				</Styled.SectionTopLine>
				<Styled.ListOfItems>
					{customBankHolidays.map(({ id, name, date, everyYear }) => (
						<Styled.ListItem key={id}>
							<Styled.ListItemTitle>{name}</Styled.ListItemTitle>
							<Styled.ListItemDate>{`${dayjs(date).format('MMM DD, YYYY')} ${
								everyYear ? '(Yearly)' : '(Once)'
							}`}</Styled.ListItemDate>
							<Styled.ListItemIcons>
								<Styled.ListItemIcon onClick={() => handleUpdateCustomHolidays(id)}>
									<EditIcon fill={COLORS.darkGray2} width='24px' height='24px' />
								</Styled.ListItemIcon>
								<Popconfirm
									placement='bottomRight'
									title='Are you sure?'
									onConfirm={() => id && handleDeleteCustomHoliday(id)}
									okText='Yes'
									cancelText='Cancel'
								>
									<Styled.ListItemIcon onClick={() => undefined}>
										<DeleteIcon fill={COLORS.darkGray2} width='24px' height='24px' />
									</Styled.ListItemIcon>
								</Popconfirm>
							</Styled.ListItemIcons>
						</Styled.ListItem>
					))}
				</Styled.ListOfItems>
			</Styled.Section>
			<Styled.Section>
				<Styled.SectionTitle>Vacation Leave Days</Styled.SectionTitle>
				<Styled.SectionTopLine>
					<Field name='standardVacationLeaveDays'>
						{({ input, meta }) => (
							<FieldWrapper
								name='standardVacationLeaveDays'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								required
							>
								<Input
									{...input}
									type='number'
									value={genericVacationLeaveDays}
									onChange={(value) => setGenericVacationLeaveDays(value.currentTarget.value)}
									placeholder='20'
								/>
							</FieldWrapper>
						)}
					</Field>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={showModal}
						disabled={!!loading?.createUpdateVacationSettingsLoad}
					>
						Save Holiday Allowance
					</HRThemedButton>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={() => handleUpdateVacationLeaveDays()}
					>
						Add Custom Time Off
					</HRThemedButton>
				</Styled.SectionTopLine>
				<Styled.ListOfItems>
					{vacationLeaveDays
						.filter((vacation) => vacation.employee !== null)
						.map(({ id, employee, days }) => (
							<Styled.ListItem key={id}>
								<Styled.ListItemTitle>{employee?.fullName}</Styled.ListItemTitle>
								<Styled.ListItemDate>{`${days} days`}</Styled.ListItemDate>
								<Styled.ListItemIcons>
									<Styled.ListItemIcon onClick={() => handleUpdateVacationLeaveDays(id)}>
										<EditIcon fill={COLORS.darkGray2} width='24px' height='24px' />
									</Styled.ListItemIcon>
									<Popconfirm
										placement='bottomRight'
										title='Are you sure?'
										onConfirm={() => employee?.id && handleDeleteVacationSettings(employee?.id)}
										okText='Yes'
										cancelText='Cancel'
									>
										<Styled.ListItemIcon>
											<DeleteIcon fill={COLORS.darkGray2} width='24px' height='24px' />
										</Styled.ListItemIcon>
									</Popconfirm>
								</Styled.ListItemIcons>
							</Styled.ListItem>
						))}
				</Styled.ListOfItems>
			</Styled.Section>
			<Modal
				title='Are you sure you would like to apply all changes? Please note any already approved leave may need to be manually adjusted'
				visible={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				centered
			>
				<Styled.ModalFooter>
					<HRThemedButton type='button' buttonType={ButtonTypes.secondary} onClick={handleCancel}>
						Cancel
					</HRThemedButton>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={handleGenericVacationUpdate}
						disabled={!!loading?.createUpdateVacationSettingsLoad}
					>
						Apply Changes
					</HRThemedButton>
				</Styled.ModalFooter>
			</Modal>
		</Styled.Block>
	);
};

export default HRHolidayAbsencePolicyBlock;
